import { useRouter } from 'next/router';
import { usePromptsContext } from '../../../context/prompts/PromptsContext';
import { reset } from '../../../store/DrivesSlice';
import { useAppDispatch } from '../../../store/hooks';
import { DriveItem, DriveItemType } from '../types';
import { Folder } from '../../Folders/types';
import { ProjectFragment } from '../../fragments.generated';
import { FolderActionMenuProps } from '../../Cards/folder/FolderActionMenu';

export interface RenameOptions {
  type: DriveItemType;
  id: string;
  name: string;
  description: string;
  project?: ProjectFragment;
}

const useDriveItemActions = ({
  onMoveSuccessful,
}: {
  onMoveSuccessful?: () => void;
}) => {
  const [, dispatchPrompt] = usePromptsContext();
  const appDispatch = useAppDispatch();
  const router = useRouter();

  /**
   * Open the move modal
   */
  const move = ({
    folder,
    project,
    currentDriveId,
    currentFolderId,
  }: {
    folder?: FolderActionMenuProps['folder'];
    project?: ProjectFragment;
    currentDriveId?: string;
    currentFolderId?: string;
  }) => {
    const folderInputObj: DriveItem | null = folder
      ? {
          id: folder.id,
          folderId: folder.id,
          name: folder.name,
          type: DriveItemType.Folder,
        }
      : null;

    const projectInputObj: DriveItem | null = project
      ? {
          id: project.id,
          name: project.name,
          type: DriveItemType.Project,
        }
      : null;

    dispatchPrompt({
      type: 'OpenDriveMovePrompt',
      payload: {
        driveId: currentDriveId,
        folderId: currentFolderId,
        foldersToMove: folderInputObj ? [folderInputObj] : [],
        projectsToMove: projectInputObj ? [projectInputObj] : [],
        onClose: options => {
          if (options?.isSuccess) {
            appDispatch(reset());
            if (onMoveSuccessful) onMoveSuccessful();
          }
        },
      },
    });
  };

  const open = (href?: string) => {
    if (href) router?.push(href);
  };

  const openParent = ({
    currentFolderId,
    currentDriveId,
  }: {
    currentFolderId?: string | null;
    currentDriveId?: string | null;
  }) => {
    let href;
    if (currentDriveId) {
      href = `/dashboard/workspaces/${currentDriveId}`;
      if (currentFolderId) {
        href = `/dashboard/workspaces/${currentDriveId}/folders/${currentFolderId}`;
      }
    }

    if (href) router?.push(href);
  };

  const rename = (options?: RenameOptions) => {
    const noop = () => {};
    if (!options) return;
    const { id, name, description, type, project } = options;
    if (type === DriveItemType.Folder) {
      dispatchPrompt({
        type: 'OpenFolderRenamePrompt',
        payload: {
          folder: {
            id,
            name,
            description,
          } as Folder,
          // just use default onClose which dismisses prompt
          onClose: noop,
        },
      });
    }
    if (type === DriveItemType.Project && project) {
      dispatchPrompt({
        type: 'OpenProjectRenamingPromptV2',
        payload: {
          project,
        },
      });
    }
  };

  const share = (options: {
    type: DriveItemType;
    project: ProjectFragment;
  }) => {
    const { type, project } = options;
    if (type === DriveItemType.Project) {
      dispatchPrompt({
        type: 'OpenProjectSharingPrompt',
        payload: {
          project,
        },
      });
    }
  };

  const deleteItem = (options: {
    type: DriveItemType;
    project?: ProjectFragment;
    folder?: FolderActionMenuProps['folder'];
  }) => {
    const { type, project, folder } = options;
    if (type === DriveItemType.Project && project) {
      dispatchPrompt({
        type: 'OpenProjectDeletionPrompt',
        payload: {
          projectId: project.id,
        },
      });
    } else if (type === DriveItemType.Folder && folder) {
      dispatchPrompt({
        type: 'OpenFolderDeletePrompt',
        payload: {
          folderId: folder.id,
          folderName: folder.name,
        },
      });
    }
  };

  return {
    move,
    open,
    rename,
    deleteItem,
    share,
    openParent,
  };
};

export default useDriveItemActions;
