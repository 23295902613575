import { createSlice } from '@reduxjs/toolkit';
import {
  DrivesAction,
  DrivesActionItems,
} from '../components/Drives/ActionBar/types';
import { getValidatedItems } from './drivesUtils';
import { DriveItem } from '../components/Drives/types';
import { Drive, Folder } from '../../server/graphql/__generated.types';
import { MoveActionDisableText } from '../components/Cards/folder/FolderActionMenu';

export type DrivesSliceAction = Omit<DrivesAction, 'onClick'>;

const enabledProjectActions: Array<DrivesSliceAction> = [
  {
    type: DrivesActionItems.Move,
    enabled: true,
    disabled: false,
    icon: 'move',
  },
];

const getEnabledProjectActions = ({
  isSharedDrive,
  hasReadOnlyDrivePermissions,
}: {
  isSharedDrive?: boolean;
  hasReadOnlyDrivePermissions?: boolean;
}): Array<DrivesSliceAction> => {
  const disableMoveOption =
    isSharedDrive || hasReadOnlyDrivePermissions || false;

  return [
    {
      type: DrivesActionItems.Move,
      enabled: true,
      disabled: disableMoveOption,
      icon: 'move',
      showDisabledTooltip: disableMoveOption,
      disabledTooltipText: isSharedDrive
        ? MoveActionDisableText.Shared
        : MoveActionDisableText.ReadOnlyPermissions,
    },
  ];
};

/**
 * @TODO Leaving here, future feature to get bulk download/delete
 */
const disabledProjectActions: Array<DrivesSliceAction> = [
  {
    type: DrivesActionItems.Download,
    enabled: true,
    disabled: false,
    icon: 'download',
  },
  {
    type: DrivesActionItems.Delete,
    enabled: true,
    disabled: false,
    icon: 'trash',
  },
];

export const projectActions: Array<DrivesSliceAction> = [
  ...enabledProjectActions,
  // ...disabledProjectActions,
];

type DrivesItemSelectedPayload = {
  arr: Array<DriveItem>;
  selected: boolean;
  isSharedDrive?: boolean;
  hasReadOnlyDrivePermissions?: boolean;
};

type CurrentDrivePayload = {
  driveId: Drive['id'] | null;
  drive?: Drive;
};

type CurrentFolderPayload = {
  folderId?: Folder['id'] | null;
  folder?: Folder | null;
};

type DrivesPayload = {
  drives: Drive[] | [];
};

type FoldersPayload = {
  folders: Folder[] | [];
};

interface DrivesState {
  actionBarActive: boolean;
  actionBarActions: Array<DrivesSliceAction>;
  selectedProjects: Array<DriveItem>;
  selectedFolders: Array<DriveItem>;
  itemsSelected: number;
  currentDrive: Drive | null;
  currentFolder?: Folder;
  /**
   * All the users drives
   */
  drives: Array<Drive>;
  /**
   * Folders available to user at current depth of the current drive, not ALL folders in drive
   */
  folders: Array<Folder>;
}

export const initialState: DrivesState = {
  actionBarActive: false,
  actionBarActions: [],
  selectedFolders: [],
  selectedProjects: [],
  itemsSelected: 0,
  currentDrive: null,
  currentFolder: undefined,
  drives: [],
  folders: [],
};

const drivesSlice = createSlice({
  name: 'drives',
  initialState,
  reducers: {
    setActions: (
      state,
      action: {
        payload: {
          actions: Array<DrivesAction>;
        };
      }
    ) => {
      return {
        ...state,
        actions: action.payload.actions,
      };
    },
    setSelectedProjects: (
      state: DrivesState,
      action: { payload: DrivesItemSelectedPayload }
    ) => {
      const { arr, selected, isSharedDrive, hasReadOnlyDrivePermissions } =
        action.payload;
      const selectedProjects = getValidatedItems(
        state.selectedProjects,
        arr,
        selected
      );
      const itemsSelected =
        selectedProjects.length + state.selectedFolders.length;

      const test = {
        ...state,
        actionBarActions: getEnabledProjectActions({
          isSharedDrive,
          hasReadOnlyDrivePermissions,
        }),
        actionBarActive:
          !!selectedProjects.length || !!state.selectedFolders.length,
        selectedProjects,
        itemsSelected,
      };

      return test;
    },
    setSelectedFolders: (
      state: DrivesState,
      action: { payload: DrivesItemSelectedPayload }
    ) => {
      const { arr, selected, isSharedDrive, hasReadOnlyDrivePermissions } =
        action.payload;
      const selectedFolders = getValidatedItems(
        state.selectedFolders,
        arr,
        selected
      );
      const itemsSelected =
        selectedFolders.length + state.selectedProjects.length;

      const test = {
        ...state,
        actionBarActions: getEnabledProjectActions({
          isSharedDrive,
          hasReadOnlyDrivePermissions,
        }),
        actionBarActive:
          !!selectedFolders.length || !!state.selectedProjects.length,
        selectedFolders,
        itemsSelected,
      };

      return test;
    },
    setUserDrives: (state: DrivesState, action: { payload: DrivesPayload }) => {
      const { drives } = action.payload;

      return {
        ...state,
        drives: drives || [],
      };
    },
    setFolders: (state: DrivesState, action: { payload: FoldersPayload }) => {
      const { folders } = action.payload;

      return {
        ...state,
        folders,
      };
    },
    setCurrentDrive: (
      state: DrivesState,
      action: { payload: CurrentDrivePayload }
    ) => {
      const { driveId, drive } = action.payload;

      if (drive)
        return {
          ...state,
          currentDrive: drive,
        };

      const currentDrive = driveId
        ? state.drives?.find(userDrive => userDrive.id === driveId)
        : null;

      return {
        ...state,
        currentDrive: currentDrive || null,
      };
    },
    setCurrentFolder: (
      state: DrivesState,
      action: { payload: CurrentFolderPayload }
    ) => {
      const { folderId, folder } = action.payload;

      if (folder) {
        return {
          ...state,
          currentFolder: folder,
        };
      }

      const currentFolder = state.folders?.find(f => f.id === folderId);

      return {
        ...state,
        currentFolder,
      };
    },
    reset: (state: DrivesState) => {
      return {
        ...initialState,
        currentDrive: state.currentDrive,
        drives: state.drives,
      };
    },
  },
});

export const {
  setActions,
  setSelectedProjects,
  setSelectedFolders,
  reset,
  setCurrentDrive,
  setCurrentFolder,
  setUserDrives,
  setFolders,
} = drivesSlice.actions;

export default drivesSlice;
