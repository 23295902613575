import { AvailableIconTypes } from '../Icon';

export enum ActionMenuType {
  primary = 'primary',
  destructive = 'destructive',
}

export interface ActionIconRecord
  extends Record<string, Partial<AvailableIconTypes> | undefined> {}

export interface ActionMenuRecord extends Record<string, ActionMenuType> {}

export type ActionEnumType = Record<string, string>;

export type Permission = {
  show: boolean;
  disabled: boolean;
  disabledTooltip?: ((children: React.ReactNode) => React.ReactNode) | null;
};
export interface ActionItemPermission extends Record<string, Permission> {}
