import { MoveActionDisableText } from '../../Cards/folder/FolderActionMenu';
import { AvailableIconTypes } from '../../Icon';

export enum DrivesActionItems {
  Download = 'Download',
  Delete = 'Delete',
  Move = 'Move to...',
}

export interface DrivesAction {
  type: DrivesActionItems;
  icon: AvailableIconTypes;
  enabled: boolean;
  disabled: boolean;
  onClick: () => void;
  showDisabledTooltip?: boolean;
  disabledTooltip?: (children: React.ReactNode) => React.ReactNode;
  disabledTooltipText?: MoveActionDisableText | string;
}

export enum DriveItemType {
  Project,
  Folder,
}
