import { Container } from '@material-ui/core';
import clsx from 'clsx';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import MuiModal, { ModalProps as MuiModalProps } from '@material-ui/core/Modal';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import ModalBodyPaper, {
  ModalBodyPaperProps as BodyProps,
} from './ModalBodyPaper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'auto',
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
    modalBody: {
      position: 'relative',
      // auto margin helps with vertical overflow
      // @see https://stackoverflow.com/a/33455342
      margin: 'auto',
      boxShadow: theme.shadows[5],
      '&:focus': {
        outline: 'none',
      },
      maxWidth: `calc(100vw)`,
      // spacing accounts for container gutter width, the content container will have minWidth 400
      [theme.breakpoints.up('sm')]: {
        minWidth: 400 + theme.spacing(4) * 2,
        width: (props: ModalProps) => {
          return props.fixedWidth ? 400 + theme.spacing(4) * 2 : undefined;
        },
      },
      [theme.breakpoints.down('xs')]: {
        width: `100vw`,
      },
    },
  })
);

export interface ModalProps extends MuiModalProps {
  open: boolean;
  onClose: () => void;
  onCloseComplete?: () => void;
  fixedWidth?: boolean;
  includePaper?: boolean;
  ModalBodyPaperProps?: BodyProps;
  classes?: {
    modalBody?: string;
  };
  children: any;
  disableGutters?: boolean;
}

/**
 * Modal
 *
 * When using Modal, please add the following child components for accessibilty:
 *    <div id="modal-title" role="dialog" aria-label="<modal title>" />
 *    <div id="modal-description" aria-label="<modal description>" />
 *
 * The Modal component is typically used with the `includePaper` and `includeDismissIcon` props.
 * These give the modal a consistent background (Paper), padding, and close/dismiss action.
 *
 * @argument {boolean?} fixedWidth If set, the modal body will be constrained to 400px + gutter width
 * @argument {boolean?} includePaper If set, include the paper background styling. The modal body
 *                      will be rendered within a Paper component.
 *
 * @example
 *    <Modal>
 *      <div id="modal-title" role="dialog" aria-label="<modal title>" />
 *      <div id="modal-description" aria-label="<modal description>" />
 *      {...}
 *    </Modal>
 *
 * @example
 *    // Disable the inclussion of dismiss icon:
 *    <Modal
 *      ModalBodyPaperProps={{
 *        includeDismissIcon: false
 *      }}
 *    />
 */
const Modal: React.FC<ModalProps> = props => {
  const {
    open,
    onClose,
    onCloseComplete,
    children,
    classes: incomingClasses = {},
    includePaper = true,
    ModalBodyPaperProps = { includeDismissIcon: true },
    BackdropComponent = Backdrop,
    fixedWidth,
    disableGutters = false,
    disableEnforceFocus = false,
    ...rest
  } = props;
  const classes = useStyles(props);

  return (
    <MuiModal
      disableEnforceFocus={disableEnforceFocus}
      closeAfterTransition
      className={classes.modal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      {...{ open, onClose, BackdropComponent, ...rest }}
    >
      <Fade in={open} onExited={onCloseComplete}>
        <div className={clsx(classes.modalBody, incomingClasses.modalBody)}>
          <Container disableGutters={disableGutters}>
            {includePaper ? (
              <ModalBodyPaper onClose={onClose} {...ModalBodyPaperProps}>
                {children}
              </ModalBodyPaper>
            ) : (
              children
            )}
          </Container>
        </div>
      </Fade>
    </MuiModal>
  );
};

export default Modal;
