import { announce as announceMessage } from '@react-aria/live-announcer';

/**
 * A wrapper for the `@react-aria/live-announcer` that provides a simple interface for announcing.
 * @see https://github.com/adobe/react-spectrum/blob/main/packages/%40react-aria/live-announcer/src/LiveAnnouncer.tsx
 */
const useSRAnnouncer = () => {
  return {
    announce: announceMessage,
  };
};

export default useSRAnnouncer;
