import { Box, Paper } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { CSSProperties } from 'react';
import IconButton from '../IconButton/IconButton';
import Icon from '../Icon';
import { paletteV2 } from '../../theme/palette';

export interface ModalBodyPaperProps {
  includeDismissIcon: boolean;
  /**
   * When a boolean, it defaults to 344. If it's a number, it'll use that value.
   * */
  includeMaxWidth?: boolean | number;
  includeHeight?: string;
  includePadding?: boolean;
  backgroundColor?: 'paper' | 'paperSecondary';
  className?: string;
  onClose: () => void;
  classes?: {
    paperChild: string;
  };
}

const useStyles = makeStyles<
  Theme,
  Pick<
    ModalBodyPaperProps,
    'backgroundColor' | 'includeHeight' | 'includePadding'
  >,
  'paper' | 'paperChild' | 'modalCloseButton'
>(theme => ({
  paper: {
    position: 'relative',
    borderRadius: theme.spacing(0.5),
    backgroundColor: ({
      backgroundColor,
    }: Pick<ModalBodyPaperProps, 'backgroundColor'>) =>
      backgroundColor === 'paper'
        ? theme.palette.background.paper
        : theme.palette.background.paperSecondary,
  },
  paperChild: {
    overflowY: ({
      includeHeight,
    }: Pick<ModalBodyPaperProps, 'includeHeight'>) =>
      includeHeight ? 'scroll' : 'initial',
    padding: ({ includePadding }) => {
      return includePadding ? theme.spacing(5, 4) : 0;
    },
    [theme.breakpoints.up('sm')]: {
      padding: ({ includePadding }) => {
        return includePadding ? theme.spacing(5, 6) : 0;
      },
    },
    ...theme.mixins.scrollBar,
  },
  modalCloseButton: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
    color: paletteV2.grey[75],
  },
}));

/**
 * ModalBodyPaper component provides a surface inside of a Modal with standardized background color
 * and padding.
 */
const ModalBodyPaper: React.FC<ModalBodyPaperProps> = ({
  className,
  includeDismissIcon,
  includeMaxWidth,
  backgroundColor = 'paper',
  includeHeight,
  includePadding = true,
  onClose,
  children,
  classes: paperClasses,
}) => {
  const classes = useStyles({ includeHeight, backgroundColor, includePadding });
  const style: CSSProperties = {};
  if (includeMaxWidth !== undefined) {
    if (typeof includeMaxWidth === 'boolean') {
      style.maxWidth = 344;
    } else {
      style.maxWidth = includeMaxWidth;
    }
  }
  if (includeHeight !== undefined) style.height = includeHeight;

  return (
    <Paper square className={clsx(classes.paper, className)}>
      <Box
        style={style}
        className={clsx(classes.paperChild, paperClasses?.paperChild)}
      >
        {children}
      </Box>
      {includeDismissIcon && (
        <IconButton
          aria-label="close"
          className={classes.modalCloseButton}
          onClick={onClose}
        >
          <Icon type="ex" color="disabled" />
        </IconButton>
      )}
    </Paper>
  );
};

export default ModalBodyPaper;
