/**
 * @see https://github.com/vercel/next.js/blob/7fd04c956d/examples/with-sentry/utils/sentry.js
 */
import * as Sentry from '@sentry/browser';
import getConfig from 'next/config';

// eslint-disable-next-line import/prefer-default-export
export const init = () => {
  const { SENTRY_DSN, SENTRY_RELEASE, _ENV } = getConfig().publicRuntimeConfig;

  if (SENTRY_DSN) {
    const integrations = [];
    if (process.env.NEXT_IS_SERVER === 'false') {
      integrations.push(
        Sentry.rewriteFramesIntegration({
          iteratee: frame => {
            if (frame.filename) {
              console.log(
                frame.filename,
                frame.filename.replace(/.*(\/_next\/.*)/, '~$1')
              );
              // eslint-disable-next-line no-param-reassign
              frame.filename = frame.filename.replace(/.*(\/_next\/.*)/, '~$1');
            }
            return frame;
          },
        })
      );
    }

    Sentry.init({
      enabled: _ENV !== 'dev',
      integrations,
      dsn: SENTRY_DSN,
      release: SENTRY_RELEASE,
      environment: _ENV,
      ignoreErrors: [
        /**
         * We're currently not using any components that use ResizeObserver, but this error does
         * seem to pop-up for different people. The upside is that it seems to be pretty harmless.
         *
         * @see https://forum.sentry.io/t/resizeobserver-loop-limit-exceeded/8402
         * @see https://stackoverflow.com/a/50387233
         *   `This error means that ResizeObserver was not able to deliver all observations within
         *   a single animation frame. It is benign (your site will not break)`
         */
        'ResizeObserver loop limit exceeded',
        /**
         * All indications point to this being related to an Outlook feature called 'SafeLinks'.
         * The IP's we see on our Sentry account related to this exception seem to also belong to
         * Microsoft. A Sentry developer in the Github Issue thread mentions that Sentry v6.9.0
         * includes a `Dedupe` integration that fixes this particular issue.
         *
         * @see https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
         * @see https://github.com/getsentry/sentry-javascript/issues/3440
         * For the event itself:
         * @see https://sentry.io/organizations/wellsaid-labs/issues/2461968296/events/ef59774635c24f24b9b607a29ecd822b/?project=5550924
         */
        'Non-Error promise rejection captured with value: Object Not Found Matching Id:',
        /**
         * After getting this error locally, it seems to stem from HubSpot
         */
        'getElementsByTagName is not a function',
        /**
         * Ignoring errors due to content moderation. This should be removed once we improve error
         * handling in the `useTTS` hook.
         */
        'This content may violate our terms. Please email support@wellsaidlabs.com',
        'This content may violate our terms. Please email moderator@wellsaidlabs.com',
        'The content you entered may violate our terms of service. Please contact Support (support@wellsaidlabs.com) to continue.',
        'Your account may have previously violated our terms of service. Please contact Content Moderation  (moderator@wellsaidlabs.com) to continue.',
        /**
         * We started seeing an error related to CodeMirror, which we don't use, it might be related
         * to a Chrome extension. Error text: `Cannot read properties of null (reading 'CodeMirror')`.
         * I think it's safe to keep the string generic and just include `CodeMirror`.
         * @see https://sentry.io/organizations/wellsaid-labs/issues/2391389495/
         */
        'CodeMirror',
      ],
    });
  }
};

/**
 * @see https://docs.sentry.io/platforms/javascript/enriching-events/identify-user/
 */
export const setUserContext = (userId: string | null) => {
  if (typeof userId === 'string') Sentry.setUser({ id: userId });
};

/**
 * Captures and Exceptiong with with the structure needed to propertly add the `state` information
 * in the `contexts` data.
 *
 * @see https://develop.sentry.dev/sdk/event-payloads/contexts/#state-context
 * @param {Error} event Error being captured
 * @param {Object} state Object with the Exception's contextual data
 */
export const captureExceptionWithState = (
  event: Error,
  state: { type: string; value: { [key: string]: string | number | undefined } }
) => {
  Sentry.captureException(event, {
    contexts: {
      [`Application State (${state.type})`]: state.value,
    },
  });
};

export const isClientError = (statusCode: number) =>
  statusCode >= 400 && statusCode <= 499;
