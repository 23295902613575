import { makeStyles } from '@material-ui/core';
import React from 'react';
import { paletteV2 } from '../../theme/palette';

interface TextLinkButtonProps {
  'aria-label': string;
  children: React.ReactNode;
  onClick: () => void;
}

const useStyles = makeStyles(() => ({
  button: {
    padding: 0,
    border: 'none',
    width: 'inherit',
    color: paletteV2.grey[0],
    '&:focus-visible': {
      color: paletteV2.lime[100],
    },
    textDecoration: 'underline',
  },
}));

const TextLinkButton = (props: TextLinkButtonProps) => {
  const { children, onClick, 'aria-label': ariaLabel } = props;
  const classes = useStyles();
  return (
    <button
      type="button"
      aria-label={ariaLabel}
      className={classes.button}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default TextLinkButton;
