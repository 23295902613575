import { createSelector } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '.';

/**
 * A hook to access the redux `dispatch` function.
 * Typing is added to the original useDispatch hook.
 */
export const useAppDispatch: () => AppDispatch = useDispatch;

/**
 * A hook to access the redux `selector` function.
 * Typing is added to the original useSelector hook.
 */
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export const selectEditMode = createSelector(
  (state: AppState) => state.editor,
  editorState => editorState.editMode
);

export const selectText = createSelector(
  (state: AppState) => state.editor,
  editorState => editorState.script.text
);
