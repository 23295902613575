import { TeamRole, UserDrive } from '../../../server/graphql/__generated.types';

export enum DriveType {
  Personal = 'Personal',
  Shared = 'Shared',
  Team = 'Team',
  Custom = 'Custom',
}

export enum DriveItemType {
  Drive,
  Folder,
  Project,
}

export interface DriveItem {
  id: string;
  driveId?: string;
  folderId?: string;
  name?: string;
  type?: DriveItemType;
  driveType?: DriveType;
  driveColor?: string;
}

export { TeamRole };
export type { UserDrive };
