import useSRAnnouncer from '../lib/useSRAnnouncer';

export const useClipboard = () => {
  const { announce } = useSRAnnouncer();
  const handleCopyTo = (valueShown: string) => {
    return navigator.clipboard.writeText(valueShown).then(() => {
      announce(`Copied: ${valueShown}`);
    });
  };

  return {
    copyToClipboard: handleCopyTo,
  };
};

export default {
  useClipboard,
};
