import React from 'react';
import { makeStyles } from '@material-ui/core';
import { AvailableIconTypes } from '../../Icon';
import ActionMenu, { ActionMenuType, Permission } from '../../ActionMenu';
import useDriveItemActions from '../../Drives/hooks/useDriveItemActions';
import { DriveItemType } from '../../Drives/types';
import { Folder } from '../../Folders/types';
import { DropdownButtonProps } from '../../DropdownButton';
import Tooltip from '../../Tooltip';

export interface FolderActionMenuProps
  extends Pick<DropdownButtonProps, 'onMouseEnter' | 'onMouseLeave'> {
  folder?: Pick<Folder, 'id' | 'name' | 'description'>;
  href?: string;
  includeOpen?: boolean;
  excludeDelete?: boolean;
  onMoveSuccessful?: () => void;
  className?: string;
  hasReadOnlyDriveAccess?: boolean;
}

export enum MoveActionDisableText {
  Shared = 'Moving coming soon!',
  ReadOnlyPermissions = `You don't have move permissions`,
}

enum FolderMenuActions {
  Open = 'Open',
  Rename = 'Rename',
  Move = 'Move to...',
  Delete = 'Delete',
}

enum ReadOnlyFolderMenuActions {
  Open = 'Open',
}

enum FolderMenuActionsWithoutOpen {
  Rename = 'Rename',
  Move = 'Move to...',
  Delete = 'Delete',
}

enum FolderMenuActionsWithoutDelete {
  Open = 'Open',
  Rename = 'Rename',
  Move = 'Move to...',
}

export const FolderActionsIcons: Record<
  FolderMenuActions,
  Partial<AvailableIconTypes> | undefined
> = {
  [FolderMenuActions.Open]: 'arrowUpRight',
  [FolderMenuActions.Rename]: 'pencil02',
  [FolderMenuActions.Move]: 'move',
  [FolderMenuActions.Delete]: 'trash04',
};

export const FolderActionsType: Record<FolderMenuActions, ActionMenuType> = {
  [FolderMenuActions.Open]: ActionMenuType.primary,
  [FolderMenuActions.Rename]: ActionMenuType.primary,
  [FolderMenuActions.Move]: ActionMenuType.primary,
  [FolderMenuActions.Delete]: ActionMenuType.destructive,
};

const FolderActionMenu = (props: FolderActionMenuProps) => {
  const {
    folder,
    href,
    includeOpen = true,
    excludeDelete,
    onMoveSuccessful,
    className,
    onMouseEnter,
    onMouseLeave,
    hasReadOnlyDriveAccess = false,
  } = props;
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const { move, open, rename, deleteItem } = useDriveItemActions({
    onMoveSuccessful,
  });

  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };
  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const disabledTooltip = (children: any) => {
    return (
      <Tooltip
        text={`You don't have move permissions`}
        placement="top-start"
        open={tooltipOpen}
        onMouseEnter={handleTooltipOpen}
        onMouseLeave={handleTooltipClose}
        onOpen={handleTooltipOpen}
        onClose={handleTooltipClose}
      >
        {children}
      </Tooltip>
    );
  };

  const FolderActionsPermissions: Record<FolderMenuActions, Permission> = {
    [FolderMenuActions.Open]: {
      show: true,
      disabled: false,
    },
    [FolderMenuActions.Rename]: {
      show: true,
      disabled: false,
    },
    [FolderMenuActions.Move]: {
      show: true,
      disabled: hasReadOnlyDriveAccess || false,
      disabledTooltip: hasReadOnlyDriveAccess ? disabledTooltip : null,
    },
    [FolderMenuActions.Delete]: {
      show: true,
      disabled: false,
    },
  };

  const reduceActions = (action: string) => {
    switch (action) {
      case FolderMenuActions.Move:
        move({ folder });
        break;
      case FolderMenuActions.Open:
        open(href);
        break;
      case FolderMenuActions.Rename: {
        if (!folder) break;
        rename({
          type: DriveItemType.Folder,
          id: folder.id,
          name: folder.name,
          description: folder.description || '',
        });
        break;
      }
      case FolderMenuActions.Delete: {
        if (!folder) break;
        deleteItem({
          type: DriveItemType.Folder,
          folder: { id: folder.id, name: folder.name },
        });
        break;
      }
      default:
        break;
    }
  };

  const actions = React.useMemo(() => {
    if (hasReadOnlyDriveAccess) return ReadOnlyFolderMenuActions;

    if (includeOpen && excludeDelete) return FolderMenuActionsWithoutDelete;

    if (!includeOpen && !excludeDelete) return FolderMenuActionsWithoutOpen;

    if (!includeOpen && excludeDelete) {
      return { Rename: 'Rename', Move: 'Move to...' };
    }

    return FolderMenuActions;
  }, [excludeDelete, hasReadOnlyDriveAccess, includeOpen]);

  const classes = useStyles();

  return (
    <ActionMenu
      id={folder?.id || ''}
      actions={actions}
      onClick={reduceActions}
      PermissionsRecord={FolderActionsPermissions}
      IconRecord={FolderActionsIcons}
      MenuRecord={FolderActionsType}
      iconFontSize="inherit"
      classes={{
        iconButton: className || classes.iconButton,
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
};

const useStyles = makeStyles(theme => ({
  iconButton: {
    fontSize: theme.spacing(2.5),
    padding: theme.spacing(0.5),
  },
}));

export default FolderActionMenu;
