import React from 'react';
import clsx from 'clsx';
import { Box, makeStyles, Theme } from '@material-ui/core';
import DropdownButton, {
  DropdownButtonMenuItem,
  DropdownButtonProps,
} from '../DropdownButton';
import { paletteV2 } from '../../theme/palette';
import Icon, { AvailableIconTypes } from '../Icon';
import Typography from '../Typography';
import {
  ActionEnumType,
  ActionIconRecord,
  ActionItemPermission,
  ActionMenuRecord,
  ActionMenuType,
} from './types';

interface ActionMenuProps<
  T extends ActionEnumType,
  J extends ActionMenuRecord,
  K extends ActionIconRecord,
  L extends ActionItemPermission
> extends Pick<ActionMenuItemProps<J, K>, 'IconRecord' | 'MenuRecord'>,
    Pick<
      DropdownButtonProps,
      | 'iconFontSize'
      | 'classes'
      | 'onMouseEnter'
      | 'onMouseLeave'
      | 'aria-label'
    > {
  id: string;
  actions: T;
  onClick: (action: keyof T) => void;
  PermissionsRecord: L;
  icon?: AvailableIconTypes;
  disabledTooltip?: ((children: React.ReactNode) => React.ReactNode) | null;
}

const useActionMenuItemStyles = makeStyles<
  Theme,
  { actionType: ActionMenuType; disabled?: boolean }
>(theme => ({
  item: {
    color: ({ actionType }) =>
      actionType === ActionMenuType.destructive
        ? paletteV2.orange[300]
        : 'inherit',
    display: 'grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
    justifyContent: 'start',
    opacity: ({ actionType, disabled }) =>
      disabled && actionType === ActionMenuType.destructive ? 0.5 : 'none',
  },
}));

interface ActionMenuItemProps<
  T extends ActionMenuRecord,
  J extends ActionIconRecord
> extends DropdownButtonMenuItem {
  action: string;
  IconRecord: J;
  MenuRecord: T;
}

export const ActionMenuItem = <
  T extends ActionMenuRecord,
  J extends ActionIconRecord
>({
  action,
  IconRecord,
  MenuRecord,
  disabled,
  disabledTooltip,
}: ActionMenuItemProps<T, J>) => {
  const classes = useActionMenuItemStyles({
    actionType: MenuRecord[action],
    disabled,
  });
  const iconType = IconRecord[action];
  const content = (
    <span className={classes.item}>
      {iconType && <Icon type={iconType} />}
      <Typography variant="sm">{action}</Typography>
    </span>
  );

  return (
    <Box className={classes.item}>
      {disabledTooltip && disabled ? disabledTooltip(content) : content}
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  menu: {
    width: theme.spacing(20),
  },
}));

const ActionMenu = <
  T extends ActionEnumType,
  J extends ActionMenuRecord,
  K extends ActionIconRecord,
  L extends ActionItemPermission
>(
  props: ActionMenuProps<T, J, K, L>
) => {
  const {
    id,
    icon = 'moreHoriz',
    IconRecord,
    MenuRecord,
    actions,
    PermissionsRecord,
    onClick,
    classes: incomingClasses,
    iconFontSize,
    onMouseEnter,
    onMouseLeave,
    'aria-label': ariaLabel,
  } = props;
  const classes = useStyles();
  const items = Object.values(actions).filter(a => PermissionsRecord[a].show);
  return (
    <DropdownButton
      id={id}
      iconType={icon}
      variant="icon"
      classes={{
        menuContent: clsx(classes.menu, incomingClasses?.menuContent),
        iconButton: incomingClasses?.iconButton,
      }}
      iconFontSize={iconFontSize}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      aria-label={ariaLabel}
    >
      {items.map(f => (
        <ActionMenuItem<J, K>
          key={f}
          id={f}
          action={f}
          onClick={() => onClick(f)}
          IconRecord={IconRecord}
          MenuRecord={MenuRecord}
          disabled={PermissionsRecord[f].disabled}
          disabledTooltip={PermissionsRecord[f].disabledTooltip}
        />
      ))}
    </DropdownButton>
  );
};

export default ActionMenu;
