import React from 'react';
import {
  Root as DropdownMenuRoot,
  Trigger,
  Portal,
  Group,
  Item,
} from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { MenuContent } from '../DropdownMenu/components';
import Typography from '../Typography';
import { PrimaryButton } from '../Buttons';
import Icon, { AvailableIconTypes, IconProps } from '../Icon';
import IconButton from '../IconButton/IconButton';
import { alphaWhite } from '../../util/cssUtil';

export interface DropdownButtonMenuItem {
  id: string;
  label?: string;
  children?: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
  isSubMenu?: boolean;
  disabledTooltip?: ((children: React.ReactNode) => React.ReactNode) | null;
}

export interface DropdownButtonProps {
  id: string;
  disabled?: boolean;
  variant?: 'primary' | 'icon';
  /**
   * Label of the main button as menu trigger
   */
  label?: string;
  /**
   * Optional icon to be placed before the label text
   */
  iconType?: AvailableIconTypes;
  /**
   * Set size of icon used in main button menu trigger
   */
  iconFontSize?: IconProps['fontSize'];
  /**
   * Optional class overrides
   */
  classes?: {
    menuContent?: string;
    iconButton?: string;
  };
  children: Array<React.ReactElement<DropdownButtonMenuItem> | React.ReactNode>;

  portal?: boolean;
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLButtonElement>;
  'aria-label'?: string;
}

/**
 * Button with a dropdown menu to click on item which will trigger callback
 * For now, only primary button variant implemented
 * But can extend this to support other buttons as the dropdown menu trigger
 */
const DropdownButton = (props: DropdownButtonProps) => {
  const {
    id,
    disabled,
    label,
    iconType,
    variant = 'primary',
    classes: incomingClasses,
    iconFontSize,
    children,
    portal = true,
    onMouseEnter,
    onMouseLeave,
    'aria-label': ariaLabel,
  } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const menuItems: React.ReactElement<typeof Item>[] = [];
  React.Children.forEach(children, child => {
    if (React.isValidElement(child)) {
      if (child.props.isSubMenu) {
        menuItems.push(child as React.ReactElement);
        return;
      }
      menuItems.push(
        <Item
          key={child.props.id}
          disabled={child.props.disabled}
          onSelect={child.props.onClick}
        >
          {child.props.label && (
            <Typography variant="sm">{child.props.label}</Typography>
          )}
          {child}
        </Item>
      );
    }
  });

  return (
    <DropdownMenuRoot open={open} onOpenChange={setOpen}>
      {variant === 'icon' && iconType && (
        <Trigger asChild disabled={disabled}>
          <IconButton
            id={`${id}-button`}
            color="default"
            aria-label={ariaLabel || `Open menu`}
            data-e2e="wsl-dropdown-menu-btn"
            className={clsx(classes.iconButton, incomingClasses?.iconButton, {
              [classes.open]: open,
            })}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            disabled={disabled}
          >
            <Icon color="secondary" type={iconType} fontSize={iconFontSize} />
          </IconButton>
        </Trigger>
      )}

      {variant === 'primary' && (
        <Trigger asChild disabled={disabled}>
          <PrimaryButton
            id={`${id}-button`}
            className={classes.button}
            classes={{ label: classes.label }}
            aria-label={ariaLabel || `Open menu`}
            disabled={disabled}
          >
            {iconType && <Icon type={iconType} />}
            <Typography variant="sm" bold uppercased>
              {label}
            </Typography>
          </PrimaryButton>
        </Trigger>
      )}

      {portal && (
        <Portal>
          <MenuContent
            id={`${id}-menu-content`}
            align="start"
            className={clsx(classes.content, incomingClasses?.menuContent)}
          >
            <Group>{menuItems}</Group>
          </MenuContent>
        </Portal>
      )}
      {!portal && (
        <MenuContent
          id={`${id}-menu-content`}
          align="start"
          className={clsx(classes.content, incomingClasses?.menuContent)}
        >
          <Group>{menuItems}</Group>
        </MenuContent>
      )}
    </DropdownMenuRoot>
  );
};

const useStyles = makeStyles(theme => ({
  content: {
    '& div[role="menuitem"] > *': {
      minWidth: theme.spacing(8.5),
    },
    borderTopLeftRadius: 0,
  },
  label: {
    display: 'flex',
    gap: theme.spacing(0.5),
  },
  button: {
    width: theme.spacing(12.5),
    padding: theme.spacing(1, 2),
    '&[data-state="open"]': {
      backgroundColor: theme.palette.contained.hover,
    },
    '&.Mui-focusVisible': {
      '&:not(&.keyboard-focus)': {
        outline: 'none',
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  iconButton: {
    borderRadius: theme.spacing(0.5),
    '&:hover': {
      backgroundColor: alphaWhite(0.09),
    },
  },
  open: {
    backgroundColor: alphaWhite(0.09),
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

export default DropdownButton;
